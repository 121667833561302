const assets = {
  images: {
    logo: require("./images/logo.png"),
    logoNavbar: require("./images/logo-navbar.png"),
    docs: require("./images/docs.png"),
    badges: require("./images/badges.png"),
    calculator: require("./images/calculator.png"),
    launchList: require("./images/lauch-list.png"),
    create: require("./images/create.png"),
    logoSmallSolana: require("./images/logo-solano.png"),
    logoSmallTron: require("./images/logo-tron.png"),
    spadPrice: require("./images/spad-price.png"),
    spadStaked: require("./images/spad-staked.png"),
    spadSupply: require("./images/spad-supply.png"),
    averageApy: require("./images/average-apy.png"),
    wallet: require("./images/wallet.png"),
    logoSmall: require("./images/logo-small.png"),
    coinSolanaSmall: require("./images/coin-solana-small.png"),
    default: require("./images/default.png"),
    stakeEarn: require("./images/stake-Earn.png"),
  },
};

export default assets;
