import React from "react";

function IconDiscord(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M9.16146 17.7984C8.68925 18.4873 8.18343 19.1525 7.64583 19.7917C3.80208 19.6823 2.34375 17.1875 2.34375 17.1875C2.39838 13.6527 3.25499 10.1764 4.84896 7.02083C6.25009 5.92471 7.95736 5.29131 9.73437 5.20833L10.2552 6.41146C10.9991 6.30819 11.749 6.25425 12.5 6.25C13.2544 6.25248 14.0077 6.30468 14.7552 6.40625L15.276 5.20312C17.0548 5.29059 18.7622 5.92952 20.1615 7.03125C21.7503 10.1845 22.6033 13.657 22.6562 17.1875C22.6562 17.1875 21.1979 19.6823 17.3542 19.7917C16.8247 19.1556 16.3275 18.4933 15.8646 17.8073M19.2292 16.2969C17.2292 17.3099 15.2943 18.3229 12.5 18.3229C9.70573 18.3229 7.77083 17.3099 5.77083 16.2969"
        stroke="#d69799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.29508 15.4062C10.2587 15.4062 11.0399 14.625 11.0399 13.6614C11.0399 12.6978 10.2587 11.9166 9.29508 11.9166C8.33146 11.9166 7.55029 12.6978 7.55029 13.6614C7.55029 14.625 8.33146 15.4062 9.29508 15.4062Z"
        stroke="#d69799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.7048 15.4062C16.6684 15.4062 17.4495 14.625 17.4495 13.6614C17.4495 12.6978 16.6684 11.9166 15.7048 11.9166C14.7411 11.9166 13.96 12.6978 13.96 13.6614C13.96 14.625 14.7411 15.4062 15.7048 15.4062Z"
        stroke="#d69799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconDiscord;
