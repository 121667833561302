import React, { useState, useEffect, useContext } from "react";
import { ethers, toBigInt } from "ethers";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import Numberinput from "../../../components/Launchpad/numberInput.js";
import SAFE from "../../../components/Launchpad/textpar.js";
import InputBlock from "../../../components/Launchpad/InputBlock.js";
import DescBlock from "../../../components/Launchpad/descBlock.js";
import Button from "../../../components/Launchpad/Button.js";
import Check from "../../../components/Launchpad/Checkbox.js";
import Checklist from "../../../components/Launchpad/ListCheck.js";
import StepBar from "../../../components/Launchpad/Stepbar.js";

import AppContext from "../../../framework/helpers/AppContext.js";
import env from "../../../hooks/data/Config.js";
import { useNavigate } from "react-router-dom";
import { Keypair, PublicKey, SYSVAR_CLOCK_PUBKEY, SystemProgram } from "@solana/web3.js";
import { Emit } from "../../../components/Alert/Alert.js";
import { getToken, getTokenAddress, getTokenProgram, nextblock, returnbignumber, testAccountsToken, updateSaleMetadata } from "../../../programs/utils.js";
import { Contract, TronContract } from "../../../programs/programs.js";
import { createProgramAddressSync } from "@project-serum/anchor/dist/cjs/utils/pubkey.js";
import { BN } from "@coral-xyz/anchor";
import DateInput from "../../../components/Timer/dateInput.js";
import SwichNetwork from "../../component/SwichNetwork.js";
const TronPresale = require("../../../abis/PreSale/TronPresale.json");
const TokenAbi = require("../../../abis/token.json")
const pda = Keypair.generate();
const metadatapda = pda.publicKey.toString();

const CreatePresale = (props) => {
  const { netid } = useContext(AppContext)
  const {
    account
  } = window.solpadContext;

  const address = account;
  const [_poolfee, setpoofee] = useState(3);
  const [amountTo, setamountTo] = useState(0);
  const [contract, setcontract] = useState(env.preSaleContractv2);

  const utokendec = netid == "solana" ? [
    9, //sol
    6, //usdc
    6, //usdt
  ] : [
    6, //trx
    6, //usdc
    6, //usdt
  ];
  const [step, setstep] = useState(0);

  const [currency, setCurrency] = useState(0);
  const [lop, setlop] = useState(netid == "tron" ? 1 : 0);
  const [Affiliate, setAffiliate] = useState(0);
  const [white, setwhite] = useState(0);
  const [progressv, setProgress] = useState("0%");
  //step1
  const [tokenB, setTokenB] = useState({
    ct: undefined,
    name: "",
    symbol: "",
    dec: 0,
    supply: "",
    allow: 0,
  });
  //step2
  const [presale_rate, setpresale_rate] = useState(1);
  const [softcap, setsoftcap] = useState(1);
  const [hardcap, sethardcap] = useState(2);
  const [minbuy, setminbuy] = useState(1);
  const [maxbuy, setmaxbuy] = useState(2);
  const [refundType, setrefundType] = useState(0);
  const [percentLiquidity, setpercentLiquidity] = useState(30);
  const [listRate, setlistRate] = useState(0);
  const [startTime, setstartTime] = useState(new Date());
  const [endTime, setendTime] = useState(new Date());
  const [tokenFee, settokenFee] = useState(0);
  const [liquidity_lockup, setliquidity_lockup] = useState(0);

  //const [metadatapda, setmetadatapda] = useState(undefined)

  //step3
  const [LogoUrl, setLogoUrl] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [YoutubeVideo, setYoutubeVideo] = useState("");
  const [Description, setDescription] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getamount();
  }, [listRate, presale_rate, hardcap, percentLiquidity, tokenB.allow]);

  useEffect(() => {
    setstartTime(_toUTC(new Date()));
    setendTime(_toUTC(new Date()));
  }, []);

  function nf(e) {
    return ("0" + e).slice(-2);
  }
  function progress() {
    return progressv;
  }
  function toUTC(func, dateA) {
    func(dateA);
  }
  function assert(bool, msg) {
    if (bool) {
      alert(msg);
      throw msg;
    }
  }
  function _toUTC(dateA) {
    const time = new Date(dateA);
    const _time = new Date(
      dateA.getUTCFullYear() +
      "-" +
      nf(dateA.getUTCMonth() + 1) +
      "-" +
      nf(dateA.getUTCDate()) +
      "T" +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      ":00"
    );

    return new Date(Date.now());
  }
  function parseUTC(dateA) {
    return (
      nf(dateA.getUTCMonth() + 1) +
      "-" +
      nf(dateA.getUTCDate()) +
      "-" +
      dateA.getUTCFullYear() +
      " At " +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      " UTC"
    );

    // return new Date(Date.now());
  }
  function now() {
    const dateA = new Date();
    const time = new Date(
      dateA.getUTCFullYear() +
      "-" +
      nf(dateA.getUTCMonth()) +
      "-" +
      nf(dateA.getUTCDate()) +
      "T" +
      nf(dateA.getUTCHours()) +
      ":" +
      nf(dateA.getUTCMinutes()) +
      ":00"
    );

    return Date.now();
  }
  function alltoBN(d) {
    let a = [];
    for (let index = 0; index < d.length; index++) {
      a.push(new BN(d[index]));
    }
    return a;
  }
  async function getpda() {

    let _pda = undefined
    const contract = new Contract(undefined, env.preSaleContractv2)

    while (_pda == undefined) {
      console.log("try")
      const fromSeed = Keypair.generate();
      const storage = await contract.methods.viewpda.view({
        da: fromSeed.publicKey,
        mint: tokenB.ct
      })
      _pda = storage
      if (storage != undefined) {
        return {
          fromSeed,
          storage
        }
      }
    }
  }
  async function create() {

    try {
      const start = parseInt(Date.parse(startTime) / 1000);
      const end = parseInt(Date.parse(endTime) / 1000);
      switch (netid) {
        case "solana":
          const { publicKey } = window.solpadContext;
          const {
            fromSeed,
            storage
          } = await getpda()
          const contract = new Contract(fromSeed.publicKey, env.preSaleContractv2)
          const space = 100000;
          const minimumBalance = await contract.connection.getMinimumBalanceForRentExemption(space);
          console.log(storage.toString(), minimumBalance)
          const createPDA = [
            await contract.methods.viewpda.instruction([], [], {
              da: contract.storage,
              mint: tokenB.ct
            })
          ]
          const createSA = [
            SystemProgram.createAccount({
              fromPubkey: publicKey,
              newAccountPubkey: fromSeed.publicKey,
              lamports: minimumBalance,
              space: space, // Defina o espaço necessário para a conta
              programId: new PublicKey(contract.contract),
            }),

          ]

          const createATA = await testAccountsToken(
            new PublicKey(tokenB.ct),
            [
              address,
              contract.storage,
              contract.defaultAccounts.of,
              storage
            ]
          );



          await updateSaleMetadata({ metadatapda: metadatapda, pda: pda },
            {
              LogoUrl,
              Website,
              Facebook,
              Twitter,
              Github,
              Telegram,
              Discord,
              Instagram,
              Reddit,
              YoutubeVideo,
              Description,
            }
          )

          const xhash = await contract.multisend()
            .useGas(25000000, 500000)
            .add(
              [...createPDA],
              []
            )
            .add(
              [
                ...createATA,
                ...createSA,
                await contract.methods.new.instruction([], [],
                  {

                    mint: tokenB.ct,
                    bank: storage,
                    sof: await getTokenAddress(tokenB.ct, contract.defaultAccounts.of),
                    sprogramWallet: await getTokenAddress(tokenB.ct, storage),
                    sownerWallet: await getTokenAddress(tokenB.ct, address),
                    tokenProgramId: await getTokenProgram(
                      new PublicKey(tokenB.ct)
                    )
                  },
                  metadatapda,
                  Array.from(
                    alltoBN([
                      returnbignumber(presale_rate, tokenB.dec),
                      returnbignumber(softcap, utokendec[currency]),
                      returnbignumber(hardcap, utokendec[currency]),
                      returnbignumber(minbuy, utokendec[currency]),
                      returnbignumber(maxbuy, utokendec[currency]),
                      refundType.toString(),
                      percentLiquidity,
                      returnbignumber(listRate, tokenB.dec),
                      start.toString(),
                      end.toString(),
                      liquidity_lockup * 1440,
                      currency,
                      lop,
                      Affiliate,
                      tokenB.dec,
                      tokenFee,
                      parseInt(Date.now() / 1000),
                    ])
                  ),
                  Array.from([])
                )
              ], [fromSeed]
            )
            .send()

         navigate("/solana/presale/" + fromSeed.publicKey.toString());
          break;

        case "tron":
          const troncontract = new TronContract(
            env.tron.preSaleContract,
            env.tron.preSaleContract,
            env.tron.preSaleFactoryAbi
          )
          const tokenContract = new TronContract(
            tokenB.ct,
            tokenB.ct,
            env.token.abi
          )
          await tokenContract.methods.approve.send(0, troncontract.contract, tokenB.supply)
          const balance = await troncontract.tronWeb.trx.getBalance(address)
          if (parseInt(balance) < 2000000000) { throw "Do you need at least 2000 trx" }
          await troncontract.methods.create.send(2500000000, tokenB.ct,
            [
              returnbignumber(presale_rate, tokenB.dec),
              returnbignumber(softcap, utokendec[currency]),
              returnbignumber(hardcap, utokendec[currency]),
              returnbignumber(minbuy, utokendec[currency]),
              returnbignumber(maxbuy, utokendec[currency]),
              refundType.toString(),
              percentLiquidity,
              returnbignumber(listRate, tokenB.dec),
              start.toString(),
              end.toString(),
              liquidity_lockup * 1440,
              currency,
              lop,
              Affiliate,
              // tokenB.dec,
              //   tokenFee,
              // parseInt(Date.now() / 1000),
            ],
            [
              LogoUrl,
              Website,
              Facebook,
              Twitter,
              Github,
              Telegram,
              Discord,
              Instagram,
              Reddit,
              YoutubeVideo,
              Description
            ]
          )
          try {
            const list =  await troncontract.methods.viewList.view()
            navigate("/tron/presale/" + list[list.length -1]);
          } catch (error) {
            
          }
         
          break;
        default:
          break;
      }


    } catch (error) {
      try {
        Emit("Error on create", error.message, "error")
      } catch (e) {
        Emit("Error on ", error, "error")
      }
      console.log(error);
    }
  }

  async function getamount() {
   // console.log(presale_rate, hardcap, listRate, percentLiquidity)
    if (
      presale_rate > 0 &&
      hardcap > 0 &&
      listRate > 0 &&
      percentLiquidity >= 15
    ) {
      const _pr = toBigInt(returnbignumber(presale_rate, tokenB.dec))
      const _lr = toBigInt(returnbignumber(listRate, tokenB.dec))
      const _hc = toBigInt(returnbignumber(hardcap, utokendec[currency]))
      const _pl = toBigInt(percentLiquidity)
      const _amountA = (_pr * _hc) / toBigInt(10 ** utokendec[currency]);
      const _a = (_hc * _pl) / 100n;

      const _b = (_lr * _a) / toBigInt(10 ** utokendec[currency])//.tofix(_a);
      const _amountB = _b - ((_b * 3n) / 100n);

     //console.log(_amountA, _amountB, _a, _b)
      setamountTo(nextblock(_amountA + _amountB, tokenB.dec));
    } else {
      setamountTo(0);
    }
  }
  async function gettoken(ct) {
    try {
      if (ct?.toString().trim().length < 0) {
        throw "invalid token"
      } else {
        //   console.log(ct?.toString().trim())
        const obj = await getToken(ct?.toString().trim(), netid);
        // console.log(obj)
        obj.ct = ct?.toString().trim();
        obj.allow = 10000000n;
        obj.dec = obj.decimals;
        setTokenB(obj);
      }
    } catch (error) {
      console.log(error)
    }
  }

  function nextstep() {
    setProgress(((step + 1) * 33).toString() + "%");
    setstep(step + 1);
  }
  function createFeeText() {
    switch (netid) {
      case "solana":

        return "Creation fee " +
          2 +
          " SOL and 3% from " +
          quote() +
          " raised and 0.5% of the supply";
      case "tron":
        return "Creation fee " +
          2500 +
          " TRX and 5% from " +
          quote() +
          " raised and 1% of the supply";
      default:
        break;
    }
  }
  function quote() {
    switch (netid) {
      case "solana":
        return (currency === 0
          ? " SOL"
          : currency === 1
            ? " USDC"
            : currency === 2
              ? " USDT"
              : " SOL");
      case "tron":
        return (currency === 0
          ? " TRX"
          : currency === 1
            ? " USDC"
            : currency === 2
              ? " USDT"
              : " TRX");
      default:
        break;
    }
  }

  let modalcreat = (
    <div>
      {progress() === "0%" && (
        /*-step-1------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-8">
                <InputBlock
                  title={"Token Address"}
                  place={"Ex. 3ic31ZwDAvL9vg8b4er22TDLaDyLakEv107CJNTsyXhW"}
                  variavel={tokenB.ct}
                  setvariavel={gettoken}
                  desc={createFeeText()}
                />
              </div>
              {netid == "solana" && <div className="col col-4">
                <InputBlock
                  title={"SplToken Fee %*"}
                  place={"0"}
                  variavel={tokenFee}
                  setvariavel={settokenFee}
                  desc={"Enter your CA Transfer Fee %"}
                />
              </div>}
            </div>
          </div>
          <div className="row">
            <div className="col col-5">
              <SAFE what={"Name"} result={tokenB.name ? tokenB.name : "-"} />
            </div>
            <div className="col col-2">
              <SAFE
                what={"Symbol"}
                result={tokenB.symbol ? tokenB.symbol : "-"}
              />
            </div>
            <div className="col col-2">
              <SAFE what={"Decimals"} result={tokenB.dec ? tokenB.dec : "-"} />
            </div>
            <div className="col col-3">
              <SAFE
                what={"Supply"}
                result={
                  tokenB.supply ? nextblock(tokenB.supply, tokenB.dec) : "-"
                }
              />
            </div>
          </div>
          <div className="form">
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Currency
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={quote()}
                  id={0}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDC"}
                  id={1}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"USDT"}
                  id={2}
                  variavel={currency}
                  setvariavel={setCurrency}
                />
              </div>
              <div className="col col-3">
                <small>
                  Users will pay with{" "}
                  {quote()}{" "}
                  for your token
                </small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Affiliate Program
            </div>
            <div className="row">
              <div className="col col-3">
                <Check
                  title={"Disable Affiliate"}
                  id={0}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>
              <div className="col col-3">
                <Check
                  title={"Enable Affiliate"}
                  id={Affiliate === 0 ? 1 : Affiliate}
                  variavel={Affiliate}
                  setvariavel={setAffiliate}
                />
              </div>

              {Affiliate !== 0 && (
                <>
                  <div className="col col-3">
                    <div className="title-input" style={{ marginLeft: "10px" }}>
                      {"% to affiliate "}
                    </div>
                    <input
                      onChange={(e) => {
                        e.target.value <= 10
                          ? setAffiliate(e.target.value)
                          : setAffiliate(Affiliate);
                      }}
                      placeholder={"%"}
                      value={Affiliate}
                      className="input"
                    />
                    <small>{"Maximum 10%"}</small>
                  </div>
                </>
              )}
            </div>
            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Listing option
            </div>
            <div className="row">
              {netid != "tron" && <div className="col col-3">
                <Check
                  title={"Automatic listing"}
                  id={0}
                  variavel={lop}
                  setvariavel={setlop}
                />
              </div>}
              <div className="col col-3">
                <Check
                  title={"Manual listing"}
                  id={1}
                  variavel={lop}
                  setvariavel={setlop}
                />
              </div>

              <div className="col col-3">
                <small>
                  {
                    lop == 0
                      ? netid == "solana"
                        ? "Automatic listing for spltokens (Token2022 is not avaliable, use manual listing)"
                        : "Automatic listing when finalize"
                      : "Manual listing "
                  }

                </small>
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "33%" && (
        /*-step-2------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Listing rate *"}
                  place={"0"}
                  variavel={listRate}
                  setvariavel={setlistRate}
                  desc={
                    "Token price per 1 " +
                    quote() +
                    " at launch"
                  }
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"PreSale Rate"}
                  place={"0"}
                  variavel={presale_rate}
                  setvariavel={setpresale_rate}
                  desc={
                    "Token price per 1 " +
                    quote() +
                    " in PreSale"
                  }
                  className=""
                />
              </div>
            </div>

            <hr style={{ margin: "15px 0" }} />
            <div className="title-input" style={{ marginLeft: "10px" }}>
              Whitelist
            </div>
            <div className="row">
              <div className="col col-2">
                <Check
                  title={"Disable"}
                  id={0}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-2">
                <Check
                  title={"Enable"}
                  id={1}
                  variavel={white}
                  setvariavel={setwhite}
                />
              </div>
              <div className="col col-4">
                <small> You can enable/disable whitelist anytime.</small>
              </div>
            </div>
            <hr style={{ margin: "15px 0" }} />

            <div className="row">
              <div className="col col-3">
                <InputBlock
                  title={
                    "softcap  " +
                    quote() +
                    "*"
                  }
                  place={"0"}
                  variavel={softcap}
                  setvariavel={setsoftcap}
                  desc={"Minimum capitalization"}
                />
              </div>
              <div className="col col-3">
                <InputBlock
                  title={
                    "hardcap  " +
                    quote() +
                    "*"
                  }
                  place={"0"}
                  variavel={hardcap}
                  setvariavel={sethardcap}
                  desc={"Maximum capitalization"}
                />
              </div>
              <div className="col col-3">
                <InputBlock
                  title={
                    "Minimum buy" +
                    quote() +
                    "*"
                  }
                  place={"0"}
                  variavel={minbuy}
                  setvariavel={setminbuy}
                  desc={"Minimum buy per wallet"}
                />
              </div>

              <div className="col col-3">
                <InputBlock
                  title={
                    "Maximum buy  " +
                    quote() +
                    "*"
                  }
                  place={"0"}
                  variavel={maxbuy}
                  setvariavel={setmaxbuy}
                  desc={"Maximum buy per wallet"}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <Checklist
                  title={"Refund Type"}
                  untitle={"Refund"}
                  ops={["Refund", "Burn"]}
                  setvariavel={(e) => {
                    setrefundType(e === "Refund" ? 0 : 1);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <DateInput
                  setvariavel={(date) => toUTC(setstartTime, date)}
                  variavel={startTime}
                  title={"Start time"}
                  desc={"Sale start time"}
                />
              </div>
              <div className="col col-6">
                <DateInput
                  setvariavel={(date) => toUTC(setendTime, date)}
                  variavel={endTime}
                  title={"End time"}
                  desc={"Sale End Time"}
                />

              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Liquidity Percent (%)*"}
                  place={"0"}
                  variavel={percentLiquidity}
                  setvariavel={setpercentLiquidity}
                  desc={
                    "How much from capitalization going to liquidity? (Minimum 30%)"
                  }
                />
              </div>
              <div className="col col-6">
                <InputBlock
                  title={"Liquidity lockup (days)"}
                  place={"0"}
                  variavel={liquidity_lockup}
                  setvariavel={(e) => {
                    setliquidity_lockup(e);
                  }}
                  desc={"How much time liquidity is lock by Solpad lock"}
                />
              </div>
            </div>
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                try {
                  assert(presale_rate <= 0, "Presale value invalid");
                  assert(softcap <= 0, "softcap value invalid");
                  assert(minbuy <= 0, "Minimum buy value invalid");
                  assert(
                    parseFloat(softcap) < parseFloat(hardcap) / 4,
                    "Soft cap had to be 25% from hardcap"
                  );
                  assert(
                    parseFloat(maxbuy) <= parseFloat(minbuy),
                    "Minimum buy > or equal Maximum buy"
                  );
                  assert(listRate <= 0, "List Rate < 0");
                  /*  assert(
                      liquidity_lockup < 30,
                      "Liquidity lockup less then 30 days"
                    );*/
                  assert(
                    percentLiquidity < 15,
                    "Liquidy percent less then 15%"
                  );
                  assert(
                    percentLiquidity > 100,
                    "Liquidy percent > 100% not allowed"
                  );
                  assert(
                    parseInt(Date.parse(startTime)) <= parseInt(now()),
                    "The start date is in the past, please amend the start date to a date in the future"
                  );
                  assert(
                    parseInt(Date.parse(endTime)) <=
                    parseInt(Date.parse(startTime)),
                    "finish time < start time"
                  );

                  nextstep();
                } catch (error) { }
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
      {progress() === "66%" && (
        /*-step-4------------------------------------------------------------------------------*/
        <>
          <div className="form">
            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Image upload url*"}
                  place={"https://..."}
                  variavel={LogoUrl}
                  setvariavel={setLogoUrl}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Website *"}
                  place={"https://..."}
                  variavel={Website}
                  setvariavel={setWebsite}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Github"}
                  place={"https://..."}
                  variavel={Github}
                  setvariavel={setGithub}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Facebook"}
                  place={"https://..."}
                  variavel={Facebook}
                  setvariavel={setFacebook}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Twitter"}
                  place={"https://..."}
                  variavel={Twitter}
                  setvariavel={setTwitter}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Instagram"}
                  place={"https://..."}
                  variavel={Instagram}
                  setvariavel={setInstagram}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <InputBlock
                  title={"Telegram"}
                  place={"https://..."}
                  variavel={Telegram}
                  setvariavel={setTelegram}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Discord"}
                  place={"https://..."}
                  variavel={Discord}
                  setvariavel={setDiscord}
                  desc={""}
                />
              </div>
              <div className="col col-4">
                <InputBlock
                  title={"Reddit"}
                  place={"https://..."}
                  variavel={Reddit}
                  setvariavel={setReddit}
                  desc={""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <InputBlock
                  title={"Youtube Video url or banner url"}
                  place={"https://..."}
                  variavel={YoutubeVideo}
                  setvariavel={setYoutubeVideo}
                  desc={""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-12">
                <DescBlock
                  title={"Description"}
                  place={"Description..."}
                  variavel={Description}
                  setvariavel={setDescription}
                  desc={""}
                />
              </div>
            </div>
          </div>

          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={async () => {
                nextstep();
              }}
              text={"NEXT"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}

      {progress() === "99%" && (
        /*-step-3------------------------------------------------------------------------------*/
        // "ethers": "^5.7.2",
        <>
          <div className="form">
            <div className="row">
              <div className="col col-6">
                <SAFE
                  what={"Total token"}
                  result={amountTo ? amountTo : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Factory Address"}
                  result={netid == "solana" ? contract : env[netid].preSaleContract}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token name"}
                  result={tokenB.name ? tokenB.name : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token symbol"}
                  result={tokenB.symbol ? tokenB.symbol : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Token decimals"}
                  result={tokenB.dec ? tokenB.dec : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"PreSale Rate"}
                  result={presale_rate ? presale_rate : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Listing Rate"}
                  result={listRate ? listRate : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Liquidity Percent (%)"}
                  result={percentLiquidity ? percentLiquidity + "%" : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Affiliate"}
                  result={Affiliate ? Affiliate + "%" : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Sale method"} result={"Presale"} />
              </div>
              <div className="col col-6">
                <SAFE what={"softcap"} result={softcap ? softcap : "-"} />
              </div>
              <div className="col col-6">
                <SAFE what={"hardcap"} result={hardcap ? hardcap : "-"} />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Unsold tokens"}
                  result={
                    endTime ? (refundType === 1 ? "Burn" : "Refund") : "-"
                  }
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Minimum buy"} result={minbuy ? minbuy : "-"} />
              </div>
              <div className="col col-6">
                <SAFE what={"Maximum buy"} result={maxbuy ? maxbuy : "-"} />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"Start time"}
                  result={startTime ? parseUTC(startTime) : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE
                  what={"End time"}
                  result={endTime ? parseUTC(endTime) : "-"}
                />
              </div>
              <div className="col col-6">
                <SAFE what={"Website"} result={Website ? Website : "-"} />
              </div>
            </div>

            <hr style={{ margin: "15px 0" }} />

            {netid == "solana" && <div className="row">
              <div className="col col-12">
                <div className="warning-message">
                  <WarningAmberOutlinedIcon
                    sx={{
                      fill: "#babd41",
                      width: "1.1rem",
                      height: "1.1rem",
                    }}
                  />
                  {
                    "To authenticate and edit your metadata, copy and save this on a safe place"
                  }
                </div>

                <div className="secret-key">
                  {JSON.stringify(pda.secretKey)}
                </div>

                <Button
                  isButtonLarge={false}
                  func={() => {
                    navigator.clipboard.writeText(
                      JSON.stringify(pda.secretKey)
                    );

                    Emit(
                      "Copied",
                      "Now save in a safe place, this is your authentication to edit your sale metadata!!",
                      "load"
                    );
                  }}
                  text={"COPY"}
                  next={false}
                  className="next"
                />
              </div>
            </div>}
          </div>
          <div className="group-buttons">
            <Button
              func={() => {
                setProgress(((step - 1) * 33).toString() + "%");
                setstep(step - 1);
              }}
              text={"BACK"}
              next={false}
              className="default"
            />
            <Button
              func={() => {
                create();
              }}
              text={"PUBLISH"}
              next={true}
              className="next"
            />
          </div>
        </>
      )}
    </div>
  );

  function stepCurrent() {
    return step;
  }

  return (
    <>
      <div className="text-title">Create Presale</div>
      <SwichNetwork />
      <div className="group-create">
        <div className="time-line">
          <div className="group-step">
            <div
              className={
                stepCurrent() === 0 || stepCurrent() > 0
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">1</div>
              <div className="step-title">
                Verify Token <br /> <p>Enter the token address and verify</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 1 || stepCurrent() > 1
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">2</div>
              <div className="step-title">
                DeFi Launchpad <br /> <p>Enter the launchpad information</p>
              </div>
            </div>

            <div
              className={
                stepCurrent() === 2 || stepCurrent() > 2
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">3</div>
              <div className="step-title">
                Add Additional Info <br />{" "}
                <p>Short description about your project and project links</p>
              </div>
            </div>
            <div
              className={
                stepCurrent() === 3 || stepCurrent() > 3
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">4</div>
              <div className="step-title">
                Finish <br />{" "}
                <p>Review your information and submit your presale</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">{modalcreat}</div>
      </div>
    </>
  );
};
export default CreatePresale;
