import React, { useState, useEffect } from "react";

const Timer = (props) => {
  const timeStyle = {
    color: "#ca090b",
    fontSize: "1rem",
  };

  const timeStyleLarge = {
    color: "#ca090b",
    fontSize: "1.4rem",
    display: "flex",
    justifyContent: "center",
  };

  const [seconds, setSeconds] = useState(0);
  const [time, setTime] = useState([0, 0, 0, 0]);
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      getTimeRemaining(props.endTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  function getTimeRemaining(endTime) {
    if (endTime == "Invalid Date") {
      setTime([0, 0, 0, 0]);
      return;
    }

    const total = Date.parse(endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    if (total < 0) {
      setTime([0, 0, 0, 0]);
    } else {
      setTime([days, hours, minutes, seconds]);
    }
  }

  return (
    <div style={props.isLarge ? timeStyleLarge : timeStyle}>
      {time[0].toString().padStart(2, "0") + "d "}
      {time[1].toString().padStart(2, "0") + "h "}
      {time[2].toString().padStart(2, "0") + "m "}
      {time[3].toString().padStart(2, "0") + "s "}
    </div>
  );
};

export default Timer;
