import React from "react";

function IconFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M17.2404 1.52951C16.2737 1.42695 15.3021 1.37705 14.33 1.38001C11.1071 1.38001 9.10418 3.43276 9.10418 6.73709V8.98055H6.39689C6.26981 8.98055 6.14793 9.03103 6.05807 9.12089C5.96821 9.21075 5.91772 9.33263 5.91772 9.45972V13.1493C5.91772 13.2764 5.96821 13.3983 6.05807 13.4881C6.14793 13.578 6.26981 13.6285 6.39689 13.6285H9.10418V21.0268C9.10418 21.1539 9.15467 21.2758 9.24453 21.3656C9.33439 21.4555 9.45627 21.506 9.58335 21.506H13.3956C13.5227 21.506 13.6446 21.4555 13.7344 21.3656C13.8243 21.2758 13.8748 21.1539 13.8748 21.0268V13.6285H16.5734C16.6898 13.6285 16.8021 13.5863 16.8896 13.5095C16.9771 13.4328 17.0336 13.3269 17.0488 13.2116L17.5251 9.52201C17.5339 9.45438 17.5283 9.38564 17.5084 9.32038C17.4886 9.25512 17.4551 9.19485 17.4101 9.14359C17.3651 9.09234 17.3096 9.05128 17.2475 9.02316C17.1854 8.99504 17.1179 8.98052 17.0497 8.98055H13.8748V7.10317C13.8748 6.17359 14.0616 5.78547 15.2241 5.78547H17.1781C17.3052 5.78547 17.4271 5.73498 17.517 5.64512C17.6068 5.55526 17.6573 5.43338 17.6573 5.3063V2.00484C17.6574 1.88851 17.6151 1.77613 17.5384 1.68866C17.4617 1.6012 17.3558 1.54463 17.2404 1.52951ZM16.699 4.82617L15.2231 4.82713C13.156 4.82713 12.9164 6.12663 12.9164 7.10317V9.46067C12.9164 9.58759 12.9668 9.70932 13.0564 9.79916C13.1461 9.88899 13.2677 9.93959 13.3946 9.93984H16.5054L16.1518 12.6711H13.3956C13.2685 12.6711 13.1466 12.7216 13.0568 12.8114C12.9669 12.9013 12.9164 13.0232 12.9164 13.1503V20.5467H10.0625V13.1503C10.0625 13.0232 10.012 12.9013 9.92217 12.8114C9.83231 12.7216 9.71043 12.6711 9.58335 12.6711H6.87702V9.93984H9.58335C9.71043 9.93984 9.83231 9.88936 9.92217 9.7995C10.012 9.70963 10.0625 9.58776 10.0625 9.46067V6.73709C10.0625 3.98284 11.6581 2.33834 14.33 2.33834C15.2969 2.33834 16.17 2.39105 16.699 2.43705V4.82617Z"
        fill="#d69799"
      />
    </svg>
  );
}

export default IconFacebook;
