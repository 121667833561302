import Api from "../../programs/api";

class networks {
    constructor() {
        this.net = localStorage.getItem("solpad-main-net") != undefined
            ? localStorage.getItem("solpad-main-net")
            : "tron"

        this.callback = (e) => { }
        //  if (localStorage.getItem("solpad-main-net") == undefined)
        //  localStorage.setItem("solpad-main-net") = "tron"

    }
    setnet(id) {
        this.callback(id)
        this.net = id
        switch (id) {
            case "solana":
                this.callback(id)
                this.net = id
                break;
            case "tron":
                this.callback(id)
                this.net = id
                break;
            default:
                this.callback("solana")
                this.net = "solana"
                break;
        }
    }
    setCallback(cb) {
        this.callback = cb

    }
    rpcs() {
        switch (this.net) {
          case "solana":
                  return [
                    {
                        name: "Helius RPC",
                        url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
                        // clusterApiUrl("devnet"),
                    },
                    {
                        name: "SPAD Node",
                        url: Api.blockchain.bridge,
                    },

                    {
                        name: "Alchemy RPC",
                        url: Api.blockchain.rpc,
                    },
                ]
            case "tron":
                return [
                    {
                        name: "Tron main rpc",
                        url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
                        // clusterApiUrl("devnet"),
                    },
                    {
                        name: "Tron main rpc",
                        url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
                        // clusterApiUrl("devnet"),
                    }
                    , {
                        name: "Tron main rpc",
                        url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
                        // clusterApiUrl("devnet"),
                    }

                ]
            default:
                break;
        }
    }
}
const net = new networks()

export default net