import "../style.scss";
import assets from "../../../../../assets";
import InputBlock from "../../../../../components/Launchpad/InputBlock.js";
import { useState } from "react";
import Button from "../../../../../components/Launchpad/Button.js";
import { format } from "../../../../../programs/utils.js";

const Stake = ({
  lock,
  setlock,
  apr,
  price,
  stake,
  balance,
  priceSOL,
  priceSOLUSD,
  TOKENA,
  TOKENB,
}) => {
  const [amountToStake, setAmountToStake] = useState(0);
  const handleTabClick = (a, month) => {
    setlock(month);
  };
  function estimate() {
    const investimentInSol = parseFloat(amountToStake) * format(priceSOL);
    //console.log(parseFloat(investimentInSol), apr, format(priceSOL))
    const a = (parseFloat(investimentInSol) * parseFloat(apr)) / 100;
    //console.log(a)
    return {
      estimate: format(a),
      estimateUSD: format(a * priceSOLUSD),
    };
  }

  return (
    <>
      <div className="stake-earn">
        <div className="stake-earn__group-input">
          <div className="stake-earn__group-input--input">
            <div className="stake-earn__group-input--input--item">
              <p>
                $ {format(parseFloat(amountToStake) * parseFloat(price))} USD
              </p>
              <InputBlock
                title={""}
                place={"Enter the amount to stake..."}
                variavel={amountToStake}
                setvariavel={setAmountToStake}
                desc={""}
              />
            </div>
            <Button
              func={async () => {
                // await stake(amountToStake);
                setAmountToStake(balance);
              }}
              text={"MAX"}
              next={false}
              style={{ background: "#ca090b", color: "#fff" }}
            />
          </div>
        </div>
        <hr style={{ margin: "20px 0" }} />
        <div className="row">
          <div className="col col-12">
            <Button
              func={async () => {
                //await stake(amountToStake);
              }}
              text={"STAKE (Soon)"}
              next={true}
              style={{ background: "#ca090b", color: "#fff" }}
            />
          </div>
          <hr style={{ margin: "24px 0" }} />
          <div className="col col-12">
            <div className="stake-earn__tabs-content">
              <div className="form-value-estimated">
                Timeframe ({" "}
                {
                  /*lock > 1
                  ? lock + " Months"
                  : lock + " Month"*/ "2 Months"
                }{" "}
                ): <strong style={{ color: "#ca090b" }}>{apr}% APY</strong>
                <div className="value-estimated">
                  <div className="value-estimated__item">
                    <p>Your estimated {TOKENB}'s rewards: </p>
                    <strong>
                      {" "}
                      {estimate().estimate} {TOKENB}'s ≈ ${" "}
                      {estimate().estimateUSD} USD{" "}
                    </strong>
                  </div>
                  <div className="value-estimated__item">
                    <p>Based on the current {TOKENB} price of </p>
                    <strong>$ {priceSOLUSD} USD </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  <div className="row">
          {
            <div className="col col-3">
                <div className="stake-earn__tabs">
              <div
                  className={`tab ${lock === 1 ? "active" : ""}`}
                  onClick={() => handleTabClick(1, 1)}
                >
                  Paper Hand
                </div>
                <div
                  className={`tab ${lock === 3 ? "active" : ""}`}
                  onClick={() => handleTabClick(2, 3)}
                >
                  Ice Hand
                </div>
                <div
                  className={`tab ${lock === 6 ? "active" : ""}`}
                  onClick={() => handleTabClick(3, 6)}
                >
                  Iron Hand
                </div>
                <div
                  className={`tab ${lock === 12 ? "active" : ""}`}
                  onClick={() => handleTabClick(4, 12)}
                >
                  Diamond Hand
                </div>
              </div>
              
            </div>
          }
          <div className="col col-9">
               <div className="stake-earn__tabs-content">
                      <div className="form stake-form">
                        Timeframe ({" "}
                        {lock > 1
                              ? lock + " Months"
                              : lock + " Month"}{" "}
                        ): <strong style={{ color: "#ca090b" }}>{apr}% APY</strong>
                        <div className="value-estimated">
                          <div className="value-estimated__item">
                            <p>Your estimated {TOKENB}'s rewards:</p>
                            <strong> {estimate().estimate} {TOKENB}'s ≈ $ {estimate().estimateUSD} USD </strong>
                          </div>
                          <div className="value-estimated__item">
                            <p>Based on the current {TOKENB} price of </p>
                            <strong> $ {priceSOLUSD} USD </strong>
                          </div>
                        </div>
                      </div>
                      <Button
                        func={async () => {
                          await stake(amountToStake);
            
                        }}
                        text={"STAKE"}
                        next={true}
                        className={`stake`}
                        style={{ background: "#ca090b", color: "#fff" }}
                      />
            
                    </div>              
          </div>
        </div>*/}
      </div>
    </>
  );
};

export default Stake;
