import { useNavigate } from "react-router-dom";
import { nextblock } from "../../programs/utils";
import assets from "../../assets";
import Api from "../../programs/api";

const TrendsModal = ({ order, cts, tokenName, icon, url, uri }) => {
  
  function setLauchpad(url) {
    let st = "";
    switch (url) {
      case "/launchpads/pre_sale/":
        st = "Presale";
        break;
      case "/private_sale/":
        st = "Private Sale";
        break;
      case "/launchpads/fair_launch/":
        st = "Fair Launch";
        break;
      default:
        break;
    }
    return st;
  }

  function typeToUrl(input) {
    if (input == "Presale") {
      return "/presale";
    }
    if (input == "Private Sale") {
      return "/privatesale";
    }
    if (input == "Fair Launch") {
      return "/fairlaunch";
    }
    return "/";
  }
  const navigate = useNavigate();

  return (
    <div
      onClick={async () => {
        try {
          await Api.use(
            "https://solpad-analytics-api.azurewebsites.net/api/"
          ).event.log.click.post({
            cts: cts,
          });
        } catch (error) {}
        navigate(uri + typeToUrl(setLauchpad(url)) + "/" + cts);
      }}
      className="trending-itens"
    >
      {/*  <div style={{
                height: "35px",
                width: "35px",
                margin: "auto",
                borderRadius: "90px",
                background: `url('${"http://localhost:3000/static/media/logo.92903f20ec6e9cc5d9bd.png"}') center center / 25px no-repeat`,
            }}>

            </div>*/}

      <div className="trending-order">{`#${order} `}</div>
      <div className="trending-name">{tokenName}</div>
    </div>
  );
};
export default TrendsModal;
