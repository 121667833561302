import { useContext, useEffect } from "react";
import AppContext from "../../framework/helpers/AppContext";
import assets from "../../assets";

const SwichNetwork = () => {
    const {
        setnet,
        netid
    } = useContext(AppContext)

    useEffect(() => {

    }, [])
    return (
        <div className="group-create">
            <div className="time-line">
                <div className="group-step">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setnet("solana")
                        }}
                        className={
                            netid == "solana"
                                ? "step-actived"
                                : "step"
                        }
                    >
                        <div className="latest-pools-group-item__stake">
                            <img src={`${assets.images.logoSmallSolana}`} loading="lazy" alt="" />
                        </div>
                        <div className="step-title">
                            Solana Blockchain <br /> <p>Use solana blockchain</p>
                        </div>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setnet("tron")
                        }}
                        className={
                            netid == "tron"
                                ? "step-actived"
                                : "step"
                        }
                    >
                        <div className="latest-pools-group-item__stake">
                            <img src={`${assets.images.logoSmallTron}`} loading="lazy" alt="" />
                        </div>
                        <div className="step-title">
                            Tron Blockchain <br /> <p>Use tron blockchain</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default SwichNetwork