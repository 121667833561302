import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store.ts';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';

ReactDOM.render((
  <>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </>
  ), document.getElementById('root')
);
reportWebVitals();
