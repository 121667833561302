import { BN } from "@coral-xyz/anchor";
import Button from "../../../../../components/Launchpad/Button";
import InputBlock from "../../../../../components/Launchpad/InputBlock";

import Checkbox from "../../../../../components/Launchpad/Checkbox";
import SessionDrop from "../../SessionDrop";
import { useState } from "react";
import DescBlock from "../../../../../components/Launchpad/descBlock";
import { PublicKey } from "@solana/web3.js";
import { endedccheck, nextblock, programNetwork, quote, returnbignumber } from "../../../../../programs/utils";
import Api from "../../../../../programs/api";

function BuyHub({ object, nSendTX, load }) {
    const [investiment, setInvestiment] = useState(
        document.getElementById("investINPUT")?.value
    );
    function getQuery(query) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get(query);
        return foo;
    }
    function getTimeRemaining(endtime) {
        console.log(Date.parse(endtime), Date.parse(new Date()))
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        if (total < 0) {
            return days + "D " + hours + "H "
                + minutes + "M " + seconds + "S"
            return "Ended";
        } else {
            return days + "D " + hours + "H "
                + minutes + "M " + seconds + "S"
        }

    }
    return (
        <>
            {(endedccheck(object) == "Sale live" ||
                endedccheck(object) == "coming") && (
                    <>
                        <div className="form">
                            <div className="group-invest">
                                <div className="invest">
                                    Min{" "}
                                    <span>
                                        {nextblock(object.minbuy, object.quoteDecimals) +
                                            (quote(programNetwork(object.program).name, object.utoken))}
                                    </span>
                                </div>
                                <div className="invest">
                                    Max{" "}
                                    <span>
                                        {nextblock(object.maxbuy, object.quoteDecimals) +
                                            (quote(programNetwork(object.program).name, object.utoken))}
                                    </span>
                                </div>
                            </div>
                            {endedccheck(object) == "Sale live" && (
                                <>
                                    <div className="group-contribute">
                                        <div className="amount-max-sol">
                                            Your Balance:{" "}
                                            {nextblock(object.ubalance, object.quoteDecimals)}
                                        </div>
                                        <div className="input-contribute">
                                            <input
                                                type="text"
                                                id={"investINPUT"}
                                                onChange={(e) => {
                                                    setInvestiment(e.target.value);
                                                }}
                                                value={investiment}
                                                placeholder={
                                                    "Amount " +
                                                    (quote(programNetwork(object.program).name, object.utoken)) +
                                                    " to invest"
                                                }
                                            />
                                            <div
                                                className="btn-contribute"
                                                onClick={() => {
                                                    setInvestiment(
                                                        nextblock(object.ubalance, object.quoteDecimals)
                                                    );
                                                }}
                                            >
                                                MAX
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {!object.isOnWl && <div className="warning yellow">
                            <p className="text-warning">
                                {object.myTier == 0 ? "You are not in whitelist" : "You are in tier " + object.myTier + " whitelist"}
                            </p>
                        </div>}

                        {(endedccheck(object) == "Sale live" && object.isOnWl) &&
                            <Button
                                func={async () => {
                                   
                                    const args =
                                        programNetwork(object.program).name == "solana"
                                            ? [
                                                new BN(returnbignumber(investiment, object.quoteDecimals))
                                            ]
                                            : [
                                                returnbignumber(investiment, object.quoteDecimals),
                                                getQuery("refFrom") == null
                                                    ? "0x0000000000000000000000000000000000000000"
                                                    : getQuery("refFrom")
                                            ]
                                    /*     console.log(
                                             parseInt(object.uToken) == 0
                                             ? returnbignumber(
                                                 investiment,
                                                 object.quoteDecimals
                                             ).toString()
                                             : "0"
                                         )*/
                                         try {
                                            const hash = await nSendTX(
                                                "contribute",
                                                parseInt(object.uToken) == 0
                                                    ? returnbignumber(
                                                        investiment,
                                                        object.quoteDecimals
                                                    ).toString()
                                                    : "0",
                                                0,
                                                ...args
                                            )
                                            console.log(hash)
                                            try {
                                                await Api
                                                    .use("https://solpad-analytics-api.azurewebsites.net/api/")
                                                    .event.log.buy.post({
                                                        cts: object.cts,
                                                        hash: hash
        
                                                    })
                                            } catch (error) {
                                                //console.log(error)
                                            }

                                            load();
                                         } catch (error) {
                                            
                                         }
                                 
                                      
                                }}
                                text={"Contribute"}
                                isButtonLarge={true}
                                className="simple-button"
                            />
                        }
                    </>
                )}

        </>
    );
}
export default BuyHub