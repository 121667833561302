import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import LoadingComponent from "../../components/loading/loading";
import env from "../../hooks/data/Config.js";
import { ethers } from "ethers";
import { useTheme, Stack, Typography, Box } from "@mui/material";
import { Contract, TronContract } from "../../programs/programs.js";
import { getToken, nextblock } from "../../programs/utils.js";
import Api from "../../programs/api.js";

const tkAbi = {};
const TokenNomics = (props) => {
  const theme = useTheme();
  const chain = window.location.pathname.toString().split("/")[1];

  const [lock, setlock] = useState(
    chain == "solana"
      ? new Contract(env.lockStorage, env.lockContract)
      : chain == "tron"
      ? new TronContract(env.tron.lock, env.tron.lock, env.tron.lockabi)
      : new Contract(env.lockStorage, env.lockContract)
  );

  const [chartData, setChartData] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getdata();
  }, []);
  async function getlock(pos) {
    const obj = await Api.lock[chain].token.get("/" + pos); //await lock.call("getLock", {}, new BN(pos));
    return obj;
  }
  async function getdata() {
    const tkdata = await getToken(props.Contract, chain);
    const args =
      chain == "solana" ? [{ addr: props.Contract }] : [props.Contract];
    const ids = await lock.methods.contractLocksView.view(...args); //call("contractLocksView", { addr: props.Contract });

    let obj = {
      series: [],
      options: {
        chart: {
          background: "none",
        },
        labels: [],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        stroke: {
          width: 0,
        },
        theme: {
          mode: "dark",
        },
        tooltip: {
          fillSeriesColor: false,
        },
      },
    };
    let totalLock = 0;

    //console.log(props)

    try {
      obj.options.colors = [];

      for (let index = 0; index < ids.length; index++) {
        const data = await getlock(ids[index ]);
        if (data == undefined) {
          throw data;
        }else{
          if (data.name == undefined) {
            throw data;
          }
        }

     
        const amount = nextblock(data.amount, tkdata.decimals);
        totalLock += parseFloat(amount);
        obj.series.push(parseFloat(amount));
        obj.options.labels.push(data.name);
        obj.options.colors.push(generateColor());
      }
    } catch (error) {
      //  console.log(error)
    }

    try {
      if (props.obj.cts != undefined) {
        const _pre =
          props.obj.hardcap != undefined
            ? parseFloat(
                nextblock(props.obj.presale_rate, tkdata.decimals) *
                  nextblock(props.obj.hardcap, props.obj.quoteDecimals)
              )
            : parseFloat(nextblock(props.obj.presale_rate, tkdata.decimals));
        totalLock += _pre;
        obj.series.push(_pre);
        obj.options.labels.push("Sale");
        obj.options.colors.push("#fd728f");
      }
    } catch (error) {
      //  console.log(error)
    }
    try {
      if (props.lpamount != undefined) {
        const _pre = parseFloat(props.lpamount);
        totalLock += _pre;
        obj.series.push(_pre);
        obj.options.labels.push("Liquidity");
        obj.options.colors.push("#039bfe");
      }
    } catch (error) {
      //  console.log(error)
    }
    // console.log(obj)
    if (props.external != undefined) {

      for (let index = 0; index < props.external.length; index++) {
        try {
          const amount = parseFloat(
            nextblock(props.external[index].value, tkdata.decimals)
          );
          totalLock += amount;
          obj.series.push(amount);
          obj.options.labels.push(props.external[index].what);
        } catch (error) {
          break;
        }
      }
    }

    /* quanto foi disponibilizado pra pre e o quanto vai pra liquidez */
    let totalFree = (
      parseFloat(nextblock(tkdata.supply, tkdata.decimals)) - totalLock
    ).toFixed(2);
    obj.series.push(parseFloat(totalFree));
    obj.options.labels.push("Unlocked");
    obj.options.colors.push("#ffcc56");

    setChartData(obj);
    setLoading(false);
  }

  function generateColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }

  return (
    <div id="chart">
      {loading && <LoadingComponent />}
      {!loading && (
        <div className="row">
          <div className="col col-8">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="donut"
              height={300}
              width="100%"
            />
          </div>

          <div className="col col-4">
            <div className="form">
              {chartData.options.colors.map((item, index) => {
                return (
                  <div className="legend">
                    <div
                      style={{
                        background: item,
                        minWidth: "50px",
                        height: "15px",
                      }}
                    ></div>
                    <p>{chartData.options.labels[index]}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TokenNomics;
