import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import SAFE from "../Launchpad/textpar.js";
import Timer from "../Timer/timer.js";
import "./card-launch.scss";
import assets from "../../assets/index";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LinearProgress from "@mui/material/LinearProgress";
import { ProgressBar } from "../Launchpad/progressBar.js";
import {
  formatNumber,
  nextblock,
  nextpercent,
  programNetwork,
  quote,
  returnbignumber,
} from "../../programs/utils.js";
import Api from "../../programs/api.js";
import { Emit } from "../Alert/Alert.js";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";

const CardLaunch = (props) => {
  const object = props.obj;
  const navigate = useNavigate();

  function setStylesStatus(status) {
    let st = "";
    switch (status) {
      case "Sale live":
        st = "salelive";
        break;
      case "Canceled":
        st = "canceled";
        break;
      case "coming":
        st = "coming";
        break;
      case "Ended":
        st = "ended";
        break;
      case "Finalized":
        st = "finalized";
        break;
      case "Claim Allowed":
        st = "claimAllowed";
        break;

      default:
        break;
    }

    return st;
  }
  function getTime(lockup) {
    return parseInt(lockup) > 1440
      ? (parseInt(lockup) / 60 / 24).toFixed(0) + " Days"
      : parseInt(lockup) > 59
      ? (parseInt(lockup) / 60).toFixed(0) + " Hours"
      : lockup + " min";
  }
  function setLauchpad(url) {
    let st = "";
    switch (url) {
      case "/launchpads/pre_sale/":
        st = "Presale";
        break;
      case "/private_sale/":
        st = "Private Sale";
        break;
      case "/launchpads/fair_launch/":
        st = "Fair Launch";
        break;
      default:
        break;
    }
    return st;
  }

  function typeToUrl(input) {
    if (input == "Presale") {
      return "/presale";
    }
    if (input == "Private Sale") {
      return "/privatesale";
    }
    if (input == "Fair Launch") {
      return "/fairlaunch";
    }
    return "/";
  }
  function isFav(cts) {
    try {
      return localStorage.getItem("solpad-local-fav") != undefined
        ? JSON.parse(localStorage.getItem("solpad-local-fav"))[cts] != undefined
          ? true
          : false
        : false;
    } catch (error) {
      return false;
    }
  }
  function isNot(cts) {
    try {
      return localStorage.getItem("solpad-local-not") != undefined
        ? JSON.parse(localStorage.getItem("solpad-local-not"))[cts] != undefined
          ? true
          : false
        : false;
    } catch (error) {
      return false;
    }
  }
  function removeSpacesAndUnderscores(str) {
    return str.replace(/[\s_]/g, "");
  }
  function pushNewFav(params) {
    try {
      const lc =
        localStorage.getItem("solpad-local-fav") != undefined
          ? JSON.parse(localStorage.getItem("solpad-local-fav"))
          : {};
      if (lc[object.cts] == undefined) {
        lc[object.cts] = object;
        localStorage.setItem("solpad-local-fav", JSON.stringify(lc));
      }
      Emit("Favorite added!", "", "success");
    } catch (error) {
      Emit("Failed", "", "error");
    }

    //  alert('Favorite added!');
  }
  function pushNewNot(params) {
    if (Notification.permission !== "granted") {
      Emit("Allow notifications!", "", "loading");
      // alert("Allow notifications!")
      Notification.requestPermission();
    } else {
      const targetTime = parseInt((object.startTime - 600) * 1000);
      const currentTime = new Date().getTime();
      const delay = targetTime - currentTime;

      if (delay > 0 && Notification.permission === "granted") {
        navigator.serviceWorker.getRegistration().then(function (reg) {
          setTimeout(() => {
            reg.showNotification(
              `Hello, the ${object.tokenname.toString()} ${setLauchpad(
                object.url
              )} will start now!`,
              {
                body: "Buy Now!",
                icon: object.logoURL,
                badge: "badge.png",
                data: {
                  url:
                    "/" +
                    programNetwork(object.program).name +
                    typeToUrl(setLauchpad(object.url)) +
                    "/" +
                    object.cts, // Link para onde a notificação deve redirecionar
                },
              }
            );
          }, delay); // Atraso calculado com base no horário especificado
        });
        const lc =
          localStorage.getItem("solpad-local-not") != undefined
            ? JSON.parse(localStorage.getItem("solpad-local-not"))
            : {};
        if (lc[object.cts] == undefined) {
          lc[object.cts] = object;
          localStorage.setItem("solpad-local-not", JSON.stringify(lc));
        }
        Emit("Notification added!", "", "success");
      } else if (delay <= 0) {
        Emit("The specified time has passed.", "", "error");
        // alert('The specified time has passed.');
      } else {
        Emit("Permission for notifications not granted.", "", "error");
        // alert();
      }
    }
  }
  //console.log((object.tokenname), props.nameSearch)
  return (
    <>
      {props.inlist &&
      props.isOnFilter &&
      (props.nameSearch.toString() == ""
        ? true
        : object.tokenname
            .toString()
            .toUpperCase()
            .includes(props.nameSearch.toUpperCase())) ? (
        <div className="col col-3">
          <div
            className={`card card-hover ${
              setStylesStatus(props.status) === "salelive"
                ? "card-sale"
                : setStylesStatus(props.status) === "coming"
                ? "card-coming"
                : ""
            }`}
            title={object.tokenname}
          >
            <a
              href={
                "/" +
                programNetwork(object.program).name +
                typeToUrl(setLauchpad(object.url)) +
                "/" +
                object.cts
              }
              onClick={async () => {
                try {
                  await Api.use(
                    "https://solpad-analytics-api.azurewebsites.net/api/"
                  ).event.log.click.post({
                    cts: object.cts,
                  });
                } catch (error) {}
              }}
            >
              <div className="affiliate">
                {parseInt(object.affiliated) > 0 && (
                  <>
                    <marquee direction="left">
                      affiliated {object.affiliated.toString()}%
                    </marquee>
                  </>
                )}
              </div>

              <div className="group-header">
                <div className="group-header__logo">
                  <img src={`${object.logoURL}`} alt="" />
                  <div className="token-logo">
                    <img
                      src={`${programNetwork(object.program).icon}`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="group-header__status">
                  <div className="group-top">
                    <div
                      className={
                        "group-top__status " + setStylesStatus(props.status)
                      }
                    >
                      {props.status}
                    </div>
                  </div>
                  <div className="group-header__status--name">
                    {object.tokenname}
                  </div>
                  <div className="group-header__status--type">
                    {setLauchpad(object.url)}
                  </div>
                </div>
              </div>

              {object.badges != undefined && (
                <div className="group-tags">
                  {object.badges[0] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[0]);
                      }}
                      className="tags audit"
                    >
                      AUDIT
                    </div>
                  )}
                  {object.badges[1] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[1]);
                      }}
                      className="tags kyc"
                    >
                      KYC
                    </div>
                  )}
                  {object.badges[2] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[2]);
                      }}
                      className="tags doxx"
                    >
                      DOXXED
                    </div>
                  )}
                  {object.badges[3] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[3]);
                      }}
                      className="tags alpha"
                    >
                      ALPHA
                    </div>
                  )}
                  {object.badges[4] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[4]);
                      }}
                      className="tags doxx"
                    >
                      VETTED
                    </div>
                  )}
                  {object.badges[5] != "" && (
                    <div
                      onClick={() => {
                        window.open(object.badges[5]);
                      }}
                      className="tags safu"
                    >
                      SAFU
                    </div>
                  )}
                </div>
              )}

              <ProgressBar
                price={props.price}
                object={object}
                typeSALE={setLauchpad(object.url)}
              />

              <div className="group-values">
                <div className="group-value-max">Liquidity: Manual Listing</div>
                <div className="row">
                  <div className="col col-6 p-2">
                    <div className="group-values__value">
                      softcap{" "}
                      <p>
                        {formatNumber(nextblock(object.softcap, props.dec)) +
                          quote(
                            programNetwork(object.program).name,
                            object.utoken
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="col col-6 p-2">
                    <div className="group-values__value">
                      hardcap{" "}
                      <p>
                        {object.hardcap == undefined
                          ? "-"
                          : formatNumber(nextblock(object.hardcap, props.dec)) +
                            quote(
                              programNetwork(object.program).name,
                              object.utoken
                            )}
                      </p>
                    </div>
                  </div>
                  <div className="col col-6 p-2">
                    <div className="group-values__value">
                      Liquidity{" "}
                      <p>
                        {object.percentLiquidity == undefined
                          ? "-"
                          : nextpercent(object.percentLiquidity, 2) + " %"}
                      </p>
                    </div>
                  </div>
                  <div className="col col-6 p-2">
                    <div className="group-values__value">
                      Locked
                      <p>
                        {object.liquidity_lockup == undefined
                          ? "-"
                          : getTime(object.liquidity_lockup)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="group-values__value-flex">
                  <div className="flex-value">
                    Min Buy
                    <p>
                      {formatNumber(nextblock(object.minbuy, props.dec)) +
                        quote(
                          programNetwork(object.program).name,
                          object.utoken
                        )}
                    </p>
                  </div>

                  <div className="flex-value">
                    Max Buy
                    <p>
                      {formatNumber(nextblock(object.maxbuy, props.dec)) +
                        quote(
                          programNetwork(object.program).name,
                          object.utoken
                        )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="group-time">
                {props.status !== "Canceled" &&
                  props.status !== "Ended" &&
                  !object.ended && (
                    <>
                      <div className="group-time__label">
                        <AccessTimeIcon sx={{ fontSize: "1rem" }} />
                        {props.status == "Sale live" ? "Ends In" : "Start In"}
                      </div>
                      <div className="group-time__time">
                        <Timer
                          endTime={
                            parseInt(Date.parse(new Date()) / 1000) >=
                            parseInt(object.startTime)
                              ? new Date(
                                  parseInt(object.endTime * 1000)
                                ).toString()
                              : new Date(
                                  parseInt(object.startTime * 1000)
                                ).toString()
                          }
                        />
                      </div>
                    </>
                  )}
              </div>
            </a>
            <div
              className={
                props.status === "Sale live"
                  ? "footer-card sale"
                  : props.status === "coming"
                  ? "footer-card sale coming"
                  : "footer-card"
              }
            >
              <div className="footer-card__type">
                {object.enableWL != undefined
                  ? parseInt(object.enableWL) == 0
                    ? "Public"
                    : "Whitelist"
                  : " "}
              </div>
              <div className="group-actions">
                {!isNot(object.cts) && (
                  <div
                    className="group-actions__icon "
                    onClick={() => {
                      pushNewNot();
                    }}
                  >
                    <NotificationsNoneIcon
                      sx={{ fontSize: "20px", strokeWidth: 1 }}
                    />
                  </div>
                )}
                {isNot(object.cts) && (
                  <div className="group-actions__icon not">
                    <NotificationsIcon
                      sx={{ fontSize: "20px", strokeWidth: 1 }}
                    />
                  </div>
                )}

                {!isFav(object.cts) && (
                  <div
                    className="group-actions__icon "
                    onClick={() => {
                      pushNewFav();
                    }}
                  >
                    <FavoriteBorderIcon
                      sx={{ fontSize: "20px", strokeWidth: 1 }}
                    />
                  </div>
                )}
                {isFav(object.cts) && (
                  <div className="group-actions__icon fav">
                    <FavoriteOutlinedIcon
                      sx={{ fontSize: "20px", strokeWidth: 1 }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CardLaunch;
