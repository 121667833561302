import React, { useContext, useEffect, useState } from "react";
import InputBlock from "../../components/Launchpad/InputBlock.js";
import CheckList from "../../components/Launchpad/ListCheck.js";
import Button from "../../components/Launchpad/Button.js";
import DescBlock from "../../components/Launchpad/descBlock.js";
import Checkbox from "../../components/Launchpad/Checkbox.js";
import AppContext from "../../framework/helpers/AppContext.js";
import SwichNetwork from "../component/SwichNetwork.js";
import { toBigInt } from "ethers";
import { solana } from "@ant-design/web3-solana";
import { tronWeb } from "../../programs/programs.js";
import { returnbignumber } from "../../programs/utils.js";
import tokensTron from "./token/tron/tokens.js";
import { Emit } from "../../components/Alert/Alert.js";
const CreateToken = (props) => {
  const {
    setnet,
    netid
  } = useContext(AppContext)
  useEffect(() => {

  }, [])
  // const [contracts, setcontracts] = useState(0);
  const [step, setstep] = useState(0);

  //---step 0
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState(0);
  const [tokenStandard, setTokenStandard] = useState(0);
  const [fee, setFee] = useState(0);
  const [maxfee, setMaxfee] = useState(0);
  const [decimais, setDecimais] = useState(6);
  const [mintAuthority, setMintAuthority] = useState(undefined);
  const [mintAuthorityCheck, setMintAuthorityCheck] = useState(1);
  const [freezeAuthority, setFreezeAuthority] = useState(undefined);
  const [freezeAuthorityCheck, setFreezeAuthorityCheck] = useState(1);
  const [updateAuthority, setUpdateAuthority] = useState(undefined);
  const [updateAuthorityCheck, setUpdateAuthorityCheck] = useState(1);

  //---step 1
  const [logoURL, setlogoURL] = useState("");
  const [Website, setWebsite] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [Twitter, setTwitter] = useState("");
  const [Github, setGithub] = useState("");
  const [Telegram, setTelegram] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Reddit, setReddit] = useState("");
  const [Youtube, setYoutube] = useState("");
  const [Description, setDescription] = useState("");
  const [metadataMutable, setMetadataMutable] = useState(0);
  //evms

  //standart
  const [_ecoFee_B, set_ecoFee_B] = useState(0);
  const [_burnFee_B, set_burnFee_B] = useState(0);
  const [_ecoFee_S, set_ecoFee_S] = useState(0);
  const [_burnFee_S, set_burnFee_S] = useState(0);
  //dividend
  const [rewardtoken, setrewardtoken] = useState('');
  const [_liquidityFee, set_liquidityFee] = useState(0);
  const [_reflectionFee, set_reflectionFee] = useState(0);
  const [_DevFee, set_DevFee] = useState(0);
  const [_burnFee, set_burnFee] = useState(0);
  const [_marketingFee, set_marketingFee] = useState(0);
  const [_sellLiquidityFee, set_sellLiquidityFee] = useState(0);
  const [_sellReflectionFee, set_sellReflectionFee] = useState(0);
  const [_sellDevFee, set_sellDevFee] = useState(0);
  const [_sellBurnFee, set_sellBurnFee] = useState(0);
  const [_sellMarketingFee, set_sellMarketingFee] = useState(0);
  //reflection
  const [_treflectionFee, set_treflectionFee] = useState(0);
  const [_selltReflectionFee, set_selltReflectionFee] = useState(0);
  function stepCurrent() {
    return step;
  }

  function nextstep() {
    setstep(step + 1);
  }

  async function createSpl(feeBasisPoints, maxFee) {
    const { SplBuilder } = require("../../programs/programs.js");
    const builder = new SplBuilder();

    builder.asyncwrite(
      {
        decimals: decimais,
        supply: supply,
        name: name,
        symbol: symbol,
        description: Description,
        image: logoURL, //add public URL to image you'd like to use
        website: Website, //add public URL to image you'd like to use
        twitter: Twitter,
        telegram: Telegram,
        discord: Discord,
        feeBasisPoints: fee,
        maxFee: maxfee,
        mintAuthority,
        freezeAuthority,
        updateAuthority,
      },
      metadataMutable,
      tokenStandard
    );
  }
  async function create() {
    const alert = Emit("Building Token...", "", "load");
    try {

      switch (netid) {
        case "tron":

        
          const { account, signTransaction } = window.solpadContext; // Contexto do Solpad com a conta e função de assinatura
        //  console.log("Account:", account);
          tronWeb.setAddress(account);

          const { bytecode, abi } = tokensTron[tokenStandard].data
          const transaction = await tronWeb.transactionBuilder.createSmartContract({
            abi: abi, // ABI do contrato
            bytecode: bytecode, // Bytecode com os parâmetros do construtor
            feeLimit: 3500000000, // Limite da taxa em SUN (3.5 TRX)
            callValue: 0, // Valor de TRX a ser enviado ao contrato (se necessário)
            parameters: [
              name, symbol, decimais, returnbignumber(supply, decimais)
            ],
          }); // O endereço da conta em base58
          const signedTx = await signTransaction(transaction);
          const receipt = await tronWeb.trx.sendRawTransaction(signedTx);
          //console.log('Contract deployed successfully:', receipt);
          alert.update("Token Created","", "success")
          window.open(
            `https://tronscan.org/#/token20/${tronWeb.address.fromHex(transaction.contract_address)}`
          );

          break;

        default:
          break;
      }

    } catch (error) {
      alert.update("Error",error, "error")
      //console.log(error)
    }
  }
  return (
    <>
      <div className="text-title">SolPad Token Builder</div>
      <div className="text-sub-title">
        Deploy your token on the chain effortlessly
      </div>
      <SwichNetwork />
      <div className="group-create">
        {netid == "solana" && <div className="time-line">
          <div className="group-step">
            <div
              className={
                stepCurrent() === 0 || stepCurrent() > 0
                  ? "step-actived"
                  : "step"
              }
            >
              <div className="step-number">1</div>
              <div className="step-title">
                Token Information <br /> <p></p>
              </div>
            </div>
            <div className={stepCurrent() === 1 ? "step-actived" : "step"}>
              <div className="step-number">2</div>
              <div className="step-title">
                Metadata <br /> <p>Description and social media</p>
              </div>
            </div>
          </div>
        </div>}

        <div className="form-group">
          {stepCurrent() === 0 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="form">
                <div className="row">
                  <div className="col col-4">
                    <InputBlock
                      title={"Name"}
                      place={"Name"}
                      variavel={name}
                      setvariavel={setName}
                      desc={""}
                    />
                  </div>
                  <div className="col col-4">
                    <InputBlock
                      title={"Symbol"}
                      place={"Symbol"}
                      variavel={symbol}
                      setvariavel={setSymbol}
                      desc={""}
                    />
                  </div>
                  <div className="col col-4">
                    <InputBlock
                      title={"Supply"}
                      place={"0"}
                      variavel={supply}
                      setvariavel={(e) => {
                        if (e.toString() == "") {
                          setSupply(0)
                          return
                        }
                        let a = "0";
                        if (e.toString().includes(".")) {
                          a = e.toString().replaceAll(".", "");
                          // console.log(a)
                        } else {
                          a = e != undefined ? e.toString() : "0";
                        }
                        setSupply(parseInt(a));
                        //  console.log(BigInt(supply), BigInt("18446744073710" + "0".repeat(decimais)), BigInt("18446744073710" + "0".repeat(decimais)) > BigInt(supply.toString() + "0".repeat(decimais)) )
                      }}
                      desc={
                        // eslint-disable-next-line no-undef

                        netid == "solana"
                          ? toBigInt(supply.toString() + "0".repeat(decimais)) >= 18446744073709551615n
                            ? "Invalid Supply reduce decimals or supply amount"
                            : ""
                          : ""
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col col-6">
                    <CheckList
                      title={"Token Standard"}
                      untitle={"Token Standard"}
                      ops={
                        netid == "solana"
                          ? ["SPL Token", "Token 2022"]
                          : [
                            'Simple (without fees)',
                            // 'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
                            // 'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
                            // 'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
                          ]
                      }
                      setvariavel={(e) => {
                        if (netid == "solana") {
                          setTokenStandard(e == "SPL Token" ? 0 : 1);
                        } else {
                          setTokenStandard(
                            e.includes('Simples') ? 0 :
                              // e.includes('Standart') ? 1 :
                              //  e.includes('Dividend') ? 2 :
                              //  e.includes('Reflection') ? 3 :

                              0)
                        }

                      }}
                    />
                    <small>
                      {/* {tokenStandard == 0 ? "$100 in $SPAD" : "$200 in $SPAD"} */}
                    </small>
                  </div>
                  <div className="col col-6">
                    <InputBlock
                      title={"Decimals"}
                      place={"0"}
                      variavel={decimais}
                      setvariavel={(e) => {

                        setDecimais(e.toString() == "" ? 0 : parseInt(e))
                      }}
                      desc={

                        // eslint-disable-next-line no-undef
                        netid == "solana" ? BigInt(supply.toString() + "0".repeat(decimais)) >=
                          18446744073709551615n
                          ? "Invalid Supply reduce decimals or supply amount"
                          : ""
                          : ""
                      }
                    />
                  </div>
                </div>

                {(tokenStandard == 1 && netid == "solana") && (
                  <div className="row">
                    <div className="col col-6">
                      <InputBlock
                        title={"Tax Fee (%)"}
                        place={"0"}
                        variavel={fee}
                        setvariavel={setFee}
                        desc={""}
                      />
                    </div>
                    <div className="col col-6">
                      <InputBlock
                        title={"Maximum Fee"}
                        place={"0"}
                        variavel={maxfee}
                        setvariavel={setMaxfee}
                        desc={""}
                      />
                    </div>
                  </div>
                )}
                {
                  <>
                    {
                      (tokenStandard == 2 || tokenStandard == 4)
                      && <InputBlock title={"Reward Token"} place={"0x"} variavel={rewardtoken} setvariavel={setrewardtoken} desc={""} />}

                    <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)", gap: "5px" }}>
                      {tokenStandard == 1 && <InputBlock title={"Buy Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_B} setvariavel={set_ecoFee_B} desc={""} />}
                      {tokenStandard == 1 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee_B} setvariavel={set_burnFee_B} desc={""} />}
                      {tokenStandard == 1 && <InputBlock title={"Sell Ecosystem Fee  %"} place={"0"} variavel={_ecoFee_S} setvariavel={set_ecoFee_S} desc={""} />}
                      {tokenStandard == 1 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_burnFee_S} setvariavel={set_burnFee_S} desc={""} />}

                      {tokenStandard == 2 && <InputBlock title={"Buy liquidity Fee  %"} place={"0"} variavel={_liquidityFee} setvariavel={set_liquidityFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Buy Dev Fee  %"} place={"0"} variavel={_DevFee} setvariavel={set_DevFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Sell liquidity Fee  %"} place={"0"} variavel={_sellLiquidityFee} setvariavel={set_sellLiquidityFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Sell Dev Fee  %"} place={"0"} variavel={_sellDevFee} setvariavel={set_sellDevFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}
                      {tokenStandard == 2 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}


                      {tokenStandard == 3 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={""} />}
                      {tokenStandard == 3 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                      {tokenStandard == 3 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                      {tokenStandard == 3 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={""} />}
                      {tokenStandard == 3 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}
                      {tokenStandard == 3 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}

                      {tokenStandard == 4 && <InputBlock title={"Buy reflection Fee  %"} place={"0"} variavel={_reflectionFee} setvariavel={set_reflectionFee} desc={"Internal token reflection"} />}
                      {tokenStandard == 4 && <InputBlock title={"Buy Marketing Fee  %"} place={"0"} variavel={_marketingFee} setvariavel={set_marketingFee} desc={""} />}
                      {tokenStandard == 4 && <InputBlock title={"Buy Burn Fee  %"} place={"0"} variavel={_burnFee} setvariavel={set_burnFee} desc={""} />}
                      {tokenStandard == 4 && <InputBlock title={"Buy token reflection Fee  %"} place={"0"} variavel={_treflectionFee} setvariavel={set_treflectionFee} desc={"External token reflection"} />}
                      {tokenStandard == 4 && <InputBlock title={"Sell reflection Fee  %"} place={"0"} variavel={_sellReflectionFee} setvariavel={set_sellReflectionFee} desc={"Internal token reflection"} />}
                      {tokenStandard == 4 && <InputBlock title={"Sell Marketing Fee  %"} place={"0"} variavel={_sellMarketingFee} setvariavel={set_sellMarketingFee} desc={""} />}
                      {tokenStandard == 4 && <InputBlock title={"Sell Burn Fee  %"} place={"0"} variavel={_sellBurnFee} setvariavel={set_sellBurnFee} desc={""} />}
                      {tokenStandard == 4 && <InputBlock title={"Sell token reflection Fee  %"} place={"0"} variavel={_selltReflectionFee} setvariavel={set_selltReflectionFee} desc={"External token reflection"} />}


                    </div></>
                }
                <hr style={{ margin: "15px 0" }} />
                {netid == solana && <div className="row">
                  <div className="col col-4">
                    <Checkbox
                      title={"Mint Authority"}
                      id={mintAuthorityCheck}
                      variavel={mintAuthorityCheck === 0 ? 0 : 2}
                      setvariavel={() => {
                        setMintAuthorityCheck(mintAuthorityCheck === 0 ? 1 : 0);
                      }}
                    />
                    {mintAuthorityCheck == 0 && (
                      <InputBlock
                        title={""}
                        place={"Mint Authority"}
                        variavel={mintAuthority}
                        setvariavel={setMintAuthority}
                        desc={""}
                      />
                    )}
                  </div>
                  <div className="col col-4">
                    <Checkbox
                      title={"Freeze Authority"}
                      id={freezeAuthorityCheck}
                      variavel={freezeAuthorityCheck === 0 ? 0 : 2}
                      setvariavel={() => {
                        setFreezeAuthorityCheck(
                          freezeAuthorityCheck === 0 ? 1 : 0
                        );
                      }}
                    />
                    {freezeAuthorityCheck == 0 && (
                      <InputBlock
                        title={""}
                        place={"Freeze Authority"}
                        variavel={freezeAuthority}
                        setvariavel={setFreezeAuthority}
                        desc={""}
                      />
                    )}
                  </div>
                  <div className="col col-4">
                    <Checkbox
                      title={"Update Authority"}
                      id={updateAuthorityCheck}
                      variavel={updateAuthorityCheck === 0 ? 0 : 2}
                      setvariavel={() => {
                        setUpdateAuthorityCheck(
                          updateAuthorityCheck === 0 ? 1 : 0
                        );
                      }}
                    />
                    {updateAuthorityCheck == 0 && (
                      <InputBlock
                        title={""}
                        place={"Update Authority"}
                        variavel={updateAuthority}
                        setvariavel={setUpdateAuthority}
                        desc={""}
                      />
                    )}
                  </div>
                </div>}
              </div>
              <div className="group-buttons">
                {netid == "solana"
                  ? <Button
                    func={() => {
                      nextstep();
                    }}
                    text={"Next"}
                    next={true}
                    className="next"
                  />
                  :
                  <Button
                    func={() => {
                      create();
                    }}
                    text={"Deploy"}
                    next={true}
                    className="next"
                  />


                }
              </div>
            </>
          )}
          {stepCurrent() === 1 && (
            /*-step-1------------------------------------------------------------------------------*/
            <>
              <div className="form">
                <div className="title-input" style={{ marginLeft: "10px" }}>
                  Metadata Mutable
                </div>
                <div className="row">
                  <div className="col col-2">
                    <Checkbox
                      title={"Disable"}
                      id={0}
                      variavel={metadataMutable}
                      setvariavel={setMetadataMutable}
                    />
                  </div>
                  <div className="col col-2">
                    <div className="title-input"></div>
                    <Checkbox
                      title={"Enable"}
                      id={1}
                      variavel={metadataMutable}
                      setvariavel={setMetadataMutable}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-6">
                    <InputBlock
                      title={"Image Upload Url *"}
                      place={"https://..."}
                      variavel={logoURL}
                      setvariavel={setlogoURL}
                      desc={""}
                    />
                  </div>
                  <div className="col col-6">
                    <InputBlock
                      title={"Website"}
                      place={"https://..."}
                      variavel={Website}
                      setvariavel={setWebsite}
                      desc={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-2">
                    <InputBlock
                      title={"Twitter"}
                      place={"https://..."}
                      variavel={Twitter}
                      setvariavel={setTwitter}
                      desc={""}
                    />
                  </div>
                  <div className="col col-6">
                    <InputBlock
                      title={"Telegram *"}
                      place={"https://..."}
                      variavel={Telegram}
                      setvariavel={setTelegram}
                      desc={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-6">
                    <InputBlock
                      title={"Discord"}
                      place={"https://..."}
                      variavel={Discord}
                      setvariavel={setDiscord}
                      desc={""}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col col-12">
                    <DescBlock
                      title={"Description"}
                      place={""}
                      variavel={Description}
                      setvariavel={setDescription}
                      desc={""}
                    />
                  </div>
                </div>
              </div>
              <div className="group-buttons">
                <Button
                  func={() => {
                    setstep(step - 1);
                  }}
                  text={"Back"}
                  next={false}
                  className="default"
                />
                {tokenStandard == 0 ? (
                  <Button
                    func={() => {
                      createSpl(0, 0);
                    }}
                    text={"Deploy"}
                    next={true}
                    className="next"
                  />
                ) : (
                  <Button
                    func={() => {
                      createSpl(0, 0);
                    }}
                    text={"Deploy Token 2022"}
                    next={false}
                    className="next"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateToken;
