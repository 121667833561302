import React from "react";

function IconTwitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M10.3519 12.2772L3.68617 19.8845M18.1719 3.11558L12.2446 10.0961M3.16724 3.11462L16.3156 19.8855H19.9381L6.79022 3.11462H3.16724Z"
        stroke="#d69799"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconTwitter;
