import React, { useState, useMemo, useEffect } from "react";

import TronContent from "./hooks/connections/tron/content.js";
import SolanaContent from "./hooks/connections/solana/content.js";
import AppContext from "./framework/helpers/AppContext.js";
import Api from "./programs/api.js";

const Content = () => {
  if (localStorage.getItem("spad-settings") == undefined) {
    localStorage.setItem(
      "spad-settings",
      JSON.stringify({
        custom: undefined,
        priority: 0,
        selected: 0,
      })
    );
  }
  const [netid, setnetid] = useState(getNet());

  function getNet() {
    const chain = window.location.pathname.toString().split("/")[
      1
    ]

    switch (chain) {
      case "solana":
        return "solana"
      case "tron":
        return "tron"
      default:
        return localStorage.getItem("solpad-main-net") != undefined
          ? localStorage.getItem("solpad-main-net")
          : "solana"
    }

  }
  const [rpcs, setRPCS] = useState(getrpcs());

  function setnet(id) {
    switch (id) {

      case "solana":
        localStorage.setItem("solpad-main-net", "solana")
        setnetid("solana")
        setRPCS([
          {
            name: "Helius RPC",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "SPAD Node",
            url: Api.blockchain.bridge,
          },

          {
            name: "Alchemy RPC",
            url: Api.blockchain.rpc,
          },
        ])
        break;
      case "tron":
        localStorage.setItem("solpad-main-net", "tron")
        setnetid("tron")
        setRPCS([
          {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          }
          , {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          }

        ])

        break;
      default:
        localStorage.setItem("solpad-main-net", "solana")
        setnetid("solana")
        setRPCS([
          {
            name: "Helius RPC",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "SPAD Node",
            url: Api.blockchain.bridge,
          },

          {
            name: "Alchemy RPC",
            url: Api.blockchain.rpc,
          },
        ])
        break;
    }
  }
  function getrpcs() {
    switch (netid) {
      case "solana":
        return [
          {
            name: "Helius RPC",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "SPAD Node",
            url: Api.blockchain.bridge,
          },

          {
            name: "Alchemy RPC",
            url: Api.blockchain.rpc,
          },
        ]
      case "tron":
        return [
          {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          }
          , {
            name: "Tron main rpc",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          }

        ]
      default:
        return [
          {
            name: "Helius RPC",
            url: "https://neila-6djlty-fast-mainnet.helius-rpc.com/"
            // clusterApiUrl("devnet"),
          },
          {
            name: "SPAD Node",
            url: Api.blockchain.bridge,
          },

          {
            name: "Alchemy RPC",
            url: Api.blockchain.rpc,
          },
        ]
    }
  }
  return (
    <AppContext.Provider value={{
      setnet,
      netid
    }}>
      {netid == "solana" && <SolanaContent rpcs={rpcs} />}
      {netid == "tron" && <TronContent rpcs={rpcs} />}
    </AppContext.Provider>


  );
};

export default Content;
