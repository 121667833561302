import React, { useEffect, useState } from "react";
import ProjectData from "../../config";
import Check from "../Launchpad/Checkbox";
import InputBlock from "../Launchpad/InputBlock";

const {
  Name,
  Logo,
  logoUrl,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers,
} = ProjectData;
const ping = async (url) => {
  try {
    const timeout = Date.now();
    const ping = await fetch(url, { method: "POST" });
    return Date.now() - timeout;
  } catch (error) {
    return 0;
  }
};
export function Settings() {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [seconds]);

  const sts = window.solpadSettings;

  return (
    <div>
      <dialog
        id="setup_modal"
        style={{
          height: "min-content",
          width: "280px",
          borderRadius: "10px",
          background: "#fbfbfb",
          border: "none",
          position: "absolute",
          margin: "auto",
          padding: "0px 0px 20px 0px",
          boxShadow: `0px 8px 20px rgba(0, 0, 0, 0.6)`,
        }}
      >
        <form
          method="dialog"
          className="modal-box groupCreated"
          style={{ display: "grid", gap: "10px" }}
        >
          <div
            style={{
              display: "grid",
              padding: "10px 15px",
              gridTemplateColumns: "auto 50px",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#3d44b6",
                margin: "auto 0px",
              }}
            >
              Setup
            </p>
            <button
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                color: "#3d44b6",
                width: "50px",
                minWidth: "50px",
                border: "none",
              }}
            >
              ✕
            </button>
          </div>
          <div
            style={{
              display: "grid",
              padding: "10px 15px",
              gridTemplateColumns: "auto 50px",
            }}
          >
            <p
              style={{ fontWeight: "bold", fontSize: "16px", color: "#3d44b6" }}
            >
              Priority Settings
            </p>
          </div>
          <div
            style={{
              width: "95%",
              background: "#00000000",
              border: "1px solid #3d44b6",
              margin: "auto",
              display: "grid",
              gridTemplateColumns: " auto auto auto ",
              height: "40px",
              color: "#3d44b6",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={() => {
                try {
                  sts?.selectPriority(0);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.priority == 0 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >
                Fast
              </p>
            </div>
            <div
              onClick={() => {
                try {
                  sts?.selectPriority(1);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.priority == 1 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >
                Turbo
              </p>
            </div>
            <div
              onClick={() => {
                try {
                  sts?.selectPriority(2);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.priority == 2 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >
                Ultra
              </p>
            </div>
          </div>

          {false && (
            <InputBlock
              title={"Custom Priority"}
              place={"0.001"}
              variavel={sts?.selected}
              setvariavel={() => {}}
              desc={""}
            />
          )}
          <div style={{ display: "grid", gridTemplateColumns: "auto 50px" }}>
            <p
              style={{ fontWeight: "bold", fontSize: "16px", color: "#ffffff" }}
            >
              RPC Settings
            </p>
          </div>

          <div
            style={{
              width: "95%",
              background: "#00000000",
              border: "1px solid #3d44b6",
              margin: "auto",
              display: "grid",
              padding: "10px 0px",
              gridTemplateColumns: " auto",
              height: "min-content",
              borderRadius: "5px",
              color: "#3d44b6",
            }}
          >
            <div
              onClick={() => {
                try {
                  sts?.select(0);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.selected == 0 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >{`${sts?.rpcs[0].name}  (${
                sts?.rpcs[0].ping != undefined ? sts?.rpcs[0].ping : 0
              }) ms`}</p>
            </div>
            <div
              onClick={() => {
                try {
                  sts?.select(1);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.selected == 1 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >{`${sts?.rpcs[1].name}  (${
                sts?.rpcs[1].ping != undefined ? sts?.rpcs[1].ping : 0
              }) ms`}</p>
            </div>
            <div
              onClick={() => {
                try {
                  sts?.select(2);
                } catch (error) {}
              }}
              style={{
                cursor: "pointer",
                width: "90%",
                background: sts?.selected == 2 ? "#3d44b620" : "#ffffff00",
                margin: "auto",
                display: "grid",
                height: "32px",
                borderRadius: "5px",
              }}
            >
              <p
                style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}
              >{`${sts?.rpcs[2].name}  (${
                sts?.rpcs[2].ping != undefined ? sts?.rpcs[2].ping : 0
              }) ms`}</p>
            </div>
          </div>
          {/*<div style={{ height: "min-content", width: "100%", display: "grid", gap: "7px" }}>

                        {sts != undefined && <>
                            <Check
                                title={`${sts?.rpcs[0].name}  (${sts?.rpcs[0].ping != undefined ? sts?.rpcs[0].ping : 0}) ms`}
                                id={0}
                                variavel={sts?.selected}
                                setvariavel={(e) => { sts?.select(e) }}
                            />
                            <Check
                                title={`${sts?.rpcs[1].name}  (${sts?.rpcs[1].ping != undefined ? sts?.rpcs[1].ping : 0}) ms`}
                                id={1}
                                variavel={sts?.selected}
                                setvariavel={(e) => { sts?.select(e) }}
                            />
                            <Check
                                title={`${sts?.rpcs[2].name}  (${sts?.rpcs[2].ping != undefined ? sts?.rpcs[2].ping : 0}) ms`}
                                id={2}
                                variavel={sts?.selected}
                                setvariavel={(e) => { sts?.select(e) }}
                            />
                        </>}

                        </div>*/}
          {/*<InputBlock
            title={"Custom RPC"}
            place={""}
            variavel={sts?.custom}
            setvariavel={(a) => {
              sts?.setCustom(a);
            }}
            desc={""}
          />*/}
        </form>
      </dialog>
    </div>
  );
}
export function Setup(actual, setNew, setCustom, setPriority, rpcs) {
  try {
    // console.log(actual, setNew, rpcs)
    window.setup_modal.close();
    window.setup_modal.showModal();
    class Sets {
      constructor(actual, setNew, setCustom, setPriority, rpcs) {
        this.save = JSON.parse(localStorage.getItem("spad-settings"));
        this.rpcs = rpcs;
        this.selected = this.save != undefined ? this.save.selected : 0;
        this.priority = this.save != undefined ? this.save.priority : 0;
        this.custom = this.save != undefined ? this.save.custom : undefined;
        this.cache();
        this.select = (a) => {
          this.selected = a;
          setNew(a);
          this.cache();
        };
        this.selectPriority = (a) => {
          this.priority = a;
          setPriority(a);
          this.cache();
        };
        this.setCustom = (a) => {
          this.custom = a;
          setCustom(a);
          this.cache();
        };
        this.selected = actual;
      }
      cache() {
        localStorage.setItem(
          "spad-settings",
          JSON.stringify({
            custom: this.custom,
            priority: this.priority,
            selected: this.selected,
          })
        );
      }
    }
    async function load() {
      //console.log("sda")
      try {
        for (let index = 0; index < rpcs.length; index++) {
          rpcs[index]["ping"] = await ping(rpcs[index].url);
        }
        window.solpadSettings = new Sets(
          actual,
          setNew,
          setCustom,
          setPriority,
          rpcs
        );
      } catch (error) {
        window.solpadSettings = new Sets(
          actual,
          setNew,
          setCustom,
          setPriority,
          rpcs
        );
      }
      /*  setTimeout(() => {
                load()
            }, 10000);*/
    }
    load();

    //document.getElementById("success_modal_alert").getElementsByTagName("h3")[0].innerHTML = _AlertData.Title
    //  document.getElementById("success_modal_alert").getElementsByTagName("p")[0].innerHTML = _AlertData.Desc
  } catch (error) {
    console.log(error);
  }
}
export default { Settings, Setup };
